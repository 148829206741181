<template>
  <div class="book_info">
    <el-card class="book_info_card">
      <div class="book_info_title"><span></span>书籍信息</div>
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="书籍名称" prop="bookName">
          <el-input v-model="form.bookName"></el-input>
        </el-form-item>
        <el-form-item label="先导视频">
          <el-upload
            v-loading="loading"
            class="avatar-uploader-video"
            action
            :http-request="uploadVideo"
            :before-upload="beforeUploadVideo"
            :show-file-list="false"
          >
            <video
              v-if="form.picture != '' && !videoFlag"
              :src="form.picture"
              class="avatar-video video-avatar"
              controls="controls"
            >
              您的浏览器不支持视频播放
            </video>
            <i
              v-else-if="form.picture == '' && !videoFlag"
              class="el-icon-plus avatar-uploader-video-icon"
            ></i>
          </el-upload>
          <div class="addinfo_title">视频大小不能超过150MB</div>
        </el-form-item>
        <el-form-item label="先导图片">
          <book-image
            :btnShow="false"
            :bookShow="false"
            :fileList="form.bookImageQueryList"
            @updateImg="emitImg"
            @removeImg="emitImgRemove"
          />
        </el-form-item>
        <el-form-item label="书籍图片" prop="images">
          <el-upload
            class="avatar-uploader"
            action=""
            :http-request="upload"
            :show-file-list="false"
            accept="image/*"
          >
            <img v-if="form.images" :src="form.images" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div class="addinfo_title">图片比例1：1（尺寸134*134）</div>
        </el-form-item>
        <el-row :gutter="10">
          <el-col :span="10">
            <el-form-item label="作者">
              <el-input v-model="form.author" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="热度值">
              <el-input
                v-model="form.heatValue"
                placeholder="请输入"
                type="number"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="书籍分类" prop="bookType">
          <el-select v-model="form.bookType" placeholder="请选择">
            <el-option
              v-for="(item, index) in bookTypeList"
              :key="index"
              :label="item.codeName"
              :value="item.codeName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专题标签">
          <div>
            <el-tag
              v-for="(item, index) in tagList"
              :key="index"
              closable
              @close="handleClose(item)"
              >{{ item }}</el-tag
            >
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="showInput"
              >+自定义标签</el-button
            >
          </div>

          <el-tag
            :key="index"
            @click="addTag(tag.codeName)"
            v-for="(tag, index) in dynamicTags"
          >
            {{ tag.codeName }}
          </el-tag>
        </el-form-item>
        <el-form-item label="书籍简介" prop="bookAbstract">
          <el-input
            style="width: 70%"
            type="textarea"
            v-model="form.bookAbstract"
            :autosize="{ minRows: 4 }"
          />
        </el-form-item>
        <el-form-item label="图书简介" prop="bookDescriptionAddQueries">
          <book-image
            :btnShow="true"
            :bookShow="form.isBookShow"
            @booean="anstractShow"
            :fileList="form.bookDescriptionAddQueries"
            @updateImg="anstract"
            @removeImg="anstractRemove"
          />
        </el-form-item>
        <el-form-item label="知识卡片" prop="knowledgeCardsAddQueries">
          <book-image
            :btnShow="true"
            :bookShow="form.isBookCard"
            @booean="cardShow"
            :fileList="form.knowledgeCardsAddQueries"
            @updateImg="cardImg"
            @removeImg="cardRemove"
          />
        </el-form-item>
        <el-form-item label="读书心得" prop="reviewAddQueryList">
          <book-image
            :btnShow="true"
            :bookShow="form.isBookView"
            @booean="reviewShow"
            :fileList="form.reviewAddQueryList"
            @updateImg="reviewImg"
            @removeImg="reviewRemove"
          />
        </el-form-item>
      </el-form>
      <div class="submit_btn">
        <el-button type="primary" @click="subimit">保存</el-button>
        <el-button plain class="new_btn" @click="cancel">取消</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { uploadAdminVideo } from "@/api/commonVideo.js";
import { uploadAdmin, codeType } from "@/api/common.js";
import { addBook, bookListById, editBook } from "@/api/book.js";
import BookImage from "../../components/BookImage.vue";
export default {
  components: { BookImage },
  name: "bookInfo",
  data() {
    return {
      loading: false,
      videoFlag: false,
      //是否显示进度条
      videoUploadPercent: "",
      //进度条的进度，
      isShowUploadVideo: false,
      rules: {
        //验证规则
        bookName: [
          { required: true, message: "请输入书籍名称", trigger: "blur" },
        ],
        images: [{ required: true, message: "请上传书籍图片" }],
        // picture: [{ required: true, message: "请上传先导视频" }],
        bookType: [
          { required: true, message: "请选择书籍分类", trigger: "change" },
        ],
        bookDescriptionAddQueries: [
          { required: true, message: "请上传书籍图片", trigger: "blur" },
        ],
        knowledgeCardsAddQueries: [
          { required: true, message: "请上传知识卡片", trigger: "blur" },
        ],
        reviewAddQueryList: [
          { required: true, message: "请上传读书心得", trigger: "blur" },
        ],
        bookAbstract: [
          { required: true, message: "请输入书籍名称", trigger: "blur" },
        ],
      },
      bookTypeList: [], //书籍分类数据
      dynamicTags: [], //code表书籍标签
      tagList: [], //书籍标签
      //新建标签
      inputVisible: false,
      inputValue: "",
      form: {
        bookAbstract: "",
        bookName: "", //书籍名称
        author: "",
        bookDescriptionAddQueries: [],
        knowledgeCardsAddQueries: [],
        reviewAddQueryList: [],
        bookImageQueryList: [],
        bookType: "",
        heatValue: "",
        images: "",
        isTrue: false,
        tag: "",
        fileId: "",
        picture: "",
        isBookShow: false,
        isBookView: false,
        isBookCard: false,
      },
    };
  },
  computed: {
    // 0 -> 新增  1->编辑
    bookType() {
      return this.$route.query.bookType * 1;
    },
    id() {
      return this.$route.query.id;
    },
  },
  created() {
    this.dynamicTagsList();
    this.getBookType();
    if (this.bookType) {
      this.getOneBook();
    }
  },
  methods: {
    //上传前回调
    beforeUploadVideo(file) {
      var fileSize = file.size / 1024 / 1024 < 150;
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
          "video/mov",
        ].indexOf(file.type) == -1
      ) {
        this.$message("请上传正确的视频格式");
        return false;
      }
      if (!fileSize) {
        this.$message("视频大小不能超过150MB");
        return false;
      }
      this.isShowUploadVideo = false;
    },
    async uploadVideo(file) {
      this.loading = true;
      // this.fileList = []
      const form = new FormData();
      form.append("file", file.file);
      const { data: res } = await uploadAdminVideo(form);
      if (res.code === 10200) {
        this.loading = false;
        this.form.picture = res.data.data.mediaUrl;
        this.form.fileId = res.data.data.fileId;
        this.isShowUploadVideo = true;
        this.$message.success("上传成功");
      } else {
        this.$message.error("上传失败，请重新上传。");
        this.loading = false;
        this.isShowUploadVideo = false;
      }
    },
    //先导图片移除
    emitImgRemove(val) {
      this.form.bookImageQueryList = this.form.bookImageQueryList.filter(
        (item) => {
          return item !== val;
        }
      );
    },
    //先导图片返回
    emitImg(val) {
      this.form.bookImageQueryList = val;
    },
    //知识卡片是否会员
    reviewShow(val) {
      this.form.isBookView = val;
    },
    //知识卡片移除
    reviewRemove(val) {
      this.form.reviewAddQueryList = this.form.reviewAddQueryList.filter(
        (item) => {
          return item !== val;
        }
      );
    },
    //知识卡片图片返回
    reviewImg(val) {
      this.form.reviewAddQueryList = val;
    },
    //知识卡片是否会员
    cardShow(val) {
      this.form.isBookCard = val;
    },
    //知识卡片移除
    cardRemove(val) {
      this.form.knowledgeCardsAddQueries =
        this.form.knowledgeCardsAddQueries.filter((item) => {
          return item !== val;
        });
    },
    //知识卡片图片返回
    cardImg(val) {
      this.form.knowledgeCardsAddQueries = val;
    },
    //书籍简介是否会员
    anstractShow(val) {
      this.form.isBookShow = val;
    },
    //书籍简介移除
    anstractRemove(val) {
      this.form.bookDescriptionAddQueries =
        this.form.bookDescriptionAddQueries.filter((item) => {
          return item !== val;
        });
    },
    //书籍上传图片返回
    anstract(val) {
      this.form.bookDescriptionAddQueries = val;
    },
    //查看单个书籍数据
    async getOneBook() {
      const { data: res } = await bookListById({ id: this.id });
      if (res.success) {
        this.form = res.data;
        if (this.form.bookImageQueryList) {
          this.form.bookImageQueryList = this.form.bookImageQueryList.map(
            (item) => {
              return {
                name: item.bookImages,
                url: item.bookImages,
              };
            }
          );
        }
        if (this.form.bookDescriptionAddQueries) {
          this.form.bookDescriptionAddQueries =
            this.form.bookDescriptionAddQueries.map((item) => {
              return {
                name: item.images,
                url: item.images,
              };
            });
        }
        if (this.form.knowledgeCardsAddQueries) {
          this.form.knowledgeCardsAddQueries =
            this.form.knowledgeCardsAddQueries.map((item) => {
              return {
                name: item.cardsImages,
                url: item.cardsImages,
              };
            });
        }
        if (this.form.reviewAddQueryList) {
          this.form.reviewAddQueryList = this.form.reviewAddQueryList.map(
            (item) => {
              return {
                name: item.reviewImages,
                url: item.reviewImages,
              };
            }
          );
        }
        if (res.data.tag) {
          this.tagList = res.data.tag.split(",");
        }
      }
    },
    //提交
    subimit() {
      console.log(this.bookType);
      if (!this.bookType) {
        //新增
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            this.form.bookImageQueryList = this.form.bookImageQueryList.map(
              (item) => {
                return {
                  bookImages: item.url,
                };
              }
            );
            this.form.bookDescriptionAddQueries =
              this.form.bookDescriptionAddQueries.map((item) => {
                return {
                  images: item.url,
                };
              });
            this.form.knowledgeCardsAddQueries =
              this.form.knowledgeCardsAddQueries.map((item) => {
                return {
                  cardsImages: item.url,
                };
              });
            this.form.reviewAddQueryList = this.form.reviewAddQueryList.map(
              (item) => {
                return {
                  reviewImages: item.url,
                };
              }
            );
            const { data: res } = await addBook(this.form);
            if (res.success) {
              this.$message.success("新增成功!");
              this.$router.go(-1);
            } else {
              this.$message.error("新增失败，请稍候重试");
            }
          } else {
            this.$message.error("请完善基本信息");
          }
        });
      } else {
        //编辑
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            this.form.bookImageQueryList = this.form.bookImageQueryList.map(
              (item) => {
                return {
                  bookImages: item.url,
                };
              }
            );
            this.form.bookDescriptionAddQueries =
              this.form.bookDescriptionAddQueries.map((item) => {
                return {
                  images: item.url,
                };
              });
            this.form.knowledgeCardsAddQueries =
              this.form.knowledgeCardsAddQueries.map((item) => {
                return {
                  cardsImages: item.url,
                };
              });
            this.form.reviewAddQueryList = this.form.reviewAddQueryList.map(
              (item) => {
                return {
                  reviewImages: item.url,
                };
              }
            );
            const { data: res } = await editBook(this.form);
            if (res.success) {
              this.$message.success("编辑成功!");
              this.$router.go(-1);
            } else {
              this.$message.error("编辑失败，请稍候重试");
            }
          } else {
            this.$message.error("请完善基本信息");
          }
        });
      }
    },
    //取消
    cancel() {
      this.$router.go(-1);
    },
    //获取书籍分类的书籍
    async getBookType() {
      const { data: res } = await codeType({ codeType: "004" });
      if (res.data) {
        this.bookTypeList = res.data;
      }
    },
    //获取标签列表
    async dynamicTagsList() {
      const { data: res } = await codeType({ codeType: "005" });
      if (res.data) {
        this.dynamicTags = res.data;
      }
    },
    //添加标签
    addTag(row) {
      if (this.tagList.indexOf(row) < 0) {
        this.tagList.push(row);
        this.form.tag = this.tagList.join(",");
      }
    },
    //删除标签
    handleClose(row) {
      this.tagList.splice(this.tagList.indexOf(row), 1);
      this.form.tag = this.tagList.join(",");
    },
    //自定义标签
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.tagList.push(inputValue);
        this.form.tag = this.tagList.join(",");
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    //上传图片
    async upload(params) {
      const form = new FormData();
      form.append("files", params.file);
      const { data: res } = await uploadAdmin(form);
      if (res.success) {
        this.form.images = res.data.fileLook[0];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.book_info {
  width: 100%;
  overflow: hidden;

  .book_info_card {
    width: 100%;
    overflow: auto;
    height: 850px;
    box-sizing: border-box;
    padding: 60px;
  }

  .book_info_title {
    width: 100%;
    margin: 0 0 40px 0;
    overflow: hidden;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    color: #151515;
    align-items: center;

    span {
      width: 4px;
      margin-right: 2px;
      height: 18px;
      background-color: #4aa9ff;
      border-radius: 2px;
    }
  }
  .submit_btn {
    display: flex;
    height: 80px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #fff;
    width: 100%;
    margin-top: 30px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    border: 1px dashed #eee;
    width: 88px;
    height: 88px;
    line-height: 88px;
    text-align: center;
  }
  .avatar {
    width: 88px;
    height: 88px;
    display: block;
  }
  .addinfo_title {
    font-size: 14px;
    color: #999;
  }
  // 全局css 加上以下代码，可以隐藏上下箭头
  // 取消input的上下箭头
  /deep/input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  /deep/input::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
  }

  /deep/input[type="number"] {
    -moz-appearance: textfield;
  }

  .button-new-tag {
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .el-tag {
    margin-right: 12px;
    cursor: pointer;
  }

  .input-new-tag {
    width: 90px;
    vertical-align: bottom;
  }

  .avatar-uploader-video .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader-video .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-video-icon {
    font-size: 28px;
    color: #8c939d;
    border: 1px dashed #eee;
    width: 88px;
    height: 88px;
    line-height: 88px;
    text-align: center;
  }
  .avatar-video {
    width: 88px;
    height: 88px;
    display: block;
  }
}
</style>